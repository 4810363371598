import { auth } from './initFirebase';

const signIn = async (email, pass) => {
	try {
		await auth().setPersistence(auth.Auth.Persistence.LOCAL);
		const res = await auth().signInWithEmailAndPassword(email, pass);
		return res.user;
	} catch (err) {
		console.log(err);
		alert(err.message);
	}
};

const checkAuth = (handle) => auth().onAuthStateChanged(handle);

export { signIn, checkAuth };
