import React, { useMemo } from 'react';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { textFilterFn, sortByDate } from '../../utils';

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
	const count = preFilteredRows.length;
	return (
		<div className='input-group input-group-sm mb-1'>
			<input
				className='form-control'
				value={filterValue || ''}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}
				placeholder={`Поиск ${count} записей...`}
			/>
		</div>
	);
}
const Table = ({ data, columns }) => {
	const initialState = useMemo(
		() => ({
			sortBy: [
				{
					id: 'modifiedDate',
					desc: true,
				},
			],
		}),
		[]
	);
	const filterTypes = useMemo(
		() => ({
			text: textFilterFn,
		}),
		[]
	);
	const sortTypes = useMemo(
		() => ({
			sortByDate,
		}),
		[]
	);
	const defaultColumn = useMemo(
		() => ({
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			sortTypes,
			initialState,
		},
		useFilters,
		useGlobalFilter,
		useSortBy
	);

	return (
		<div>
			<table className='table mt-3' {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render('Header')}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? ' 🔽'
												: ' 🔼'
											: ''}
									</span>
									<div>{column.canFilter ? column.render('Filter') : null}</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td className={cell.column.id} {...cell.getCellProps()}>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
