import React from 'react';
import Slider from '../../Slider';

const Photo = ({ value, sliderClassName }) => {
	return (
		<div className='bSlideItem'>
			<Slider className={sliderClassName}>
				{value.map(({ photo, date }, idx) => (
					<div key={date + idx}>
						<img
							src={photo}
							className='bLogoTG img-fluid rounded mx-auto d-block'
							alt='.'
						/>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default Photo;
