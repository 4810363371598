import React, { useState } from 'react';

const SliderNav = ({ className, val }) => {
	const [slideIdx, setSlideIdx] = useState(1);
	const updateCurrentSlideIdx = () => {
		setTimeout(() => {
			setSlideIdx(+document.querySelector(`.${className} .slick-active`)?.dataset?.index + 1);
		}, 600);
	};

	const nextSlide = () => {
		const allArrow = document.querySelectorAll(`.${className} .slick-next`);
		allArrow.forEach((element) => element.click());
		updateCurrentSlideIdx();
	};
	const prevSlide = () => {
		const allArrow = document.querySelectorAll(`.${className} .slick-prev`);
		allArrow.forEach((element) => element.click());
		updateCurrentSlideIdx();
	};
	return (
		<>
			<div className='btn-group-vertical'>
				<button type='button' className='btn btn-secondary' onClick={nextSlide}>
					&#x2191;
				</button>
				<button type='button' className='btn btn-secondary' onClick={prevSlide}>
					&#x2193;
				</button>
			</div>
			<small className='text-center h-100 d-block'>
				{slideIdx} / {val}
			</small>
		</>
	);
};

export default SliderNav;
