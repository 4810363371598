import firebase from 'firebase';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: 'telegram-5f766.firebaseapp.com',
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: 'telegram-5f766.appspot.com',
	messagingSenderId: '910948954244',
	appId: process.env.REACT_APP_APP_ID,
	measurementId: 'G-MBNH8GNME8',
};
function initFirebase() {
	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	}
}
initFirebase();

const auth = firebase.auth;
const db = firebase.database();

export { firebase, auth, db };
