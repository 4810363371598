import React, { useState, useEffect } from 'react';
import { db } from '../service/initFirebase';
import { signIn, checkAuth } from '../service/firebase-service';
import SignInForm from './SignInForm';
import Spinner from './Spinner';
import TelegramTable from './TelegramTable';

import './App.css';
import { formatDataForTable } from '../utils';

const App = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [user, setUser] = useState(null);
	const [botWork, setBotWork] = useState(false);

	const dataMemo = React.useMemo(() => data, [data]);

	useEffect(() => {
		checkAuth((user) => {
			if (user) {
				setUser(user.uid);
			} else {
				setUser(null);
			}
			setLoading(false);
		});

		db.ref('/channels').on('value', (snapshot) => {
			const allNotes = formatDataForTable(snapshot);
			setData(allNotes);
		});
		db.ref('/scripts/test').on('value', (snapshot) => {
			setBotWork(snapshot.val() === 'work');
		});
	}, []);

	const signInApp = async (dataForm) => {
		const user = await signIn(dataForm.email, dataForm.pass);
		setUser(user, data);
	};
	if (loading) {
		return <Spinner />;
	}
	return (
		<div className='App'>
			{user ? (
				<TelegramTable bot={botWork} data={dataMemo} />
			) : (
				<SignInForm handle={signInApp} />
			)}
		</div>
	);
};

export default App;
