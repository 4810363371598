import React from 'react';
import { formatDate, copyToClipboard } from '../../../utils';
import Slider from '../../Slider';

const Name = ({ value, sliderClassName }) => {
	const copy = (str) => () => copyToClipboard(str);
	return (
		<div className='bSlideItem'>
			<Slider className={sliderClassName}>
				{value.map(({ name, date }, idx) => (
					<div key={date + idx}>
						<div className='text-center'>
							<div className='fw-bold'>
								{name}{' '}
								<small className='bCopy' onClick={copy(name)}>
									<svg width='24' height='24' fill='none'>
										<path
											stroke='currentColor'
											stroke-linecap='round'
											stroke-linejoin='round'
											stroke-width='1.5'
											d='M9 6.75H7.75a2 2 0 00-2 2v8.5a2 2 0 002 2h8.5a2 2 0 002-2v-8.5a2 2 0 00-2-2H15'
										/>
										<path
											stroke='currentColor'
											stroke-linecap='round'
											stroke-linejoin='round'
											stroke-width='1.5'
											d='M14 8.25h-4a1 1 0 01-1-1v-1.5a1 1 0 011-1h4a1 1 0 011 1v1.5a1 1 0 01-1 1zM9.75 12.25h4.5M9.75 15.25h4.5'
										/>
									</svg>
								</small>
							</div>
							<div className='d-flex justify-content-center mt-1'>
								<small className='text-muted'>{formatDate(date)}</small>
							</div>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default Name;
