import React from 'react';
import Slider from '../../Slider';

import { formatDate } from '../../../utils';

const About = ({ value, sliderClassName }) => {
	return (
		<div className='bSlideItem'>
			<Slider className={sliderClassName}>
				{value.map(({ about, date }, idx) => (
					<div key={date + idx}>
						<div className='text-center'>
							<textarea
								rows='6'
								className='form-control'
								disabled
								readOnly
								value={about}
							/>

							<div className='d-flex justify-content-center mt-1'>
								<small className='text-muted'>{formatDate(date)}</small>
							</div>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default About;
