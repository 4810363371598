import React from 'react';
import Header from '../Header';
import Spinner from '../Spinner';
import Table from './Table';
import { Name, Photo, About } from './Cell';
import SliderNav from './Cell/SliderNav';
import { formatDate } from '../../utils';

const TelegramTable = ({ data, bot }) => {
	const botClassName = bot ? 'success' : 'danger';

	const columns = React.useMemo(
		() => [
			{
				Header: 'ID канала',
				accessor: 'id',
			},
			{
				Header: 'Название',
				accessor: 'name',
				Cell: ({ value, row }) => (
					<Name value={value} sliderClassName={`slider${row.values.id}`} />
				),
				filter: 'text',
			},
			{
				Header: 'Аватарка',
				accessor: 'photo',
				Cell: ({ value, row }) => (
					<Photo value={value} sliderClassName={`slider${row.values.id}`} />
				),
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: 'Описание',
				accessor: 'about',
				Cell: ({ value, row }) => (
					<About value={value} sliderClassName={`slider${row.values.id}`} />
				),
				filter: 'text',
				disableSortBy: true,

				maxWidth: 100,
			},
			{
				Header: '#',
				accessor: 'sliderNav',
				Cell: ({ row, value }) => (
					<SliderNav val={value} className={`slider${row.values.id}`} />
				),
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: 'Добавлено',
				accessor: 'createDate',
				Cell: ({ value }) => <small>{formatDate(value)}</small>,
				disableFilters: true,
				sortType: 'sortByDate',
			},
			{
				Header: 'Обновлено',
				accessor: 'modifiedDate',
				Cell: ({ value }) => <small>{formatDate(value)}</small>,
				disableFilters: true,
				sortType: 'sortByDate',
				defaultCanSort: false,
				sortDescFirst: false,
			},
		],
		[]
	);

	if (!data.length) {
		return <Spinner />;
	}
	return (
		<div>
			<Header />
			<div className='container mt-3'>
				<div className='row'>
					<div className='col-md-12'>
						<div className='d-flex flex-wrap align-items-center justify-content-between'>
							<h1>Telegram monitoring</h1>
							<div className={`badge rounded-pill bg-${botClassName}`}>
								Бот {bot || 'не'} работает
							</div>
						</div>
					</div>
					<Table data={data} columns={columns} />
				</div>
			</div>
		</div>
	);
};

export default TelegramTable;
