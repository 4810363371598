import React from 'react';
import SlickSlider from 'react-slick';

const Slider = ({ children, className }) => {
	const settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		vertical: true,
		arrows: true,
		adaptiveHeight: true,
		draggable: false,
		swipe: false,
	};
	return (
		<SlickSlider className={className} {...settings}>
			{children}
		</SlickSlider>
	);
};
export default Slider;
