import React, { useState } from 'react';

const SignInForm = ({ handle }) => {
	const [dataForm, setDataForm] = useState({
		email: '',
		pass: '',
	});
	const changeInput = (e) => {
		setDataForm((state) => {
			const target = e.target;

			return {
				...state,
				[target.name]: target.value,
			};
		});
	};
	const onSubmit = (event) => {
		handle(dataForm);
		event.preventDefault();
	};
	return (
		<div className='bSignInForm'>
			<form onSubmit={onSubmit} className='form-signin'>
				<h1 className='h3 mb-3 font-weight-normal'>Вход</h1>
				<input
					name='email'
					type='email'
					id='inputEmail'
					className='form-control'
					placeholder='Email'
					required
					onChange={changeInput}
				/>
				<input
					name='pass'
					type='password'
					id='inputPassword'
					className='form-control'
					placeholder='Пароль'
					required
					onChange={changeInput}
				/>

				<button className='btn btn-lg btn-primary btn-block' type='submit'>
					Войти
				</button>
			</form>
		</div>
	);
};

export default SignInForm;
