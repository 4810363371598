import React from 'react';

const Spinner = () => {
	return (
		<div className='d-flex justify-content-center bSpinner'>
			<div className='spinner-grow text-info' role='status'>
				<span className='visually-hidden'>Загрузка...</span>
			</div>
		</div>
	);
};

export default Spinner;
