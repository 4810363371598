import * as dayjs from 'dayjs';

const formatDate = (date) => dayjs.unix(date).format('DD-MM-YYYY HH:mm');

const textFilterFn = (rows, id, filterValue) => {
	return rows.filter((row) => {
		const objValue = row.values[id];
		const isFinded = objValue.some((el) => {
			const idx = el[id].toLowerCase().indexOf(String(filterValue).toLowerCase());
			if (idx === -1) {
				return false;
			}
			return true;
		});
		return objValue !== undefined ? isFinded : true;
	});
};

const formatDataForTable = (snapshot) => {
	let allNotes = [];
	snapshot.forEach((snap) => {
		const tg = snap.val();
		const hist = tg?.history ? tg.history.reverse() : [];

		if (!tg?.test) {
			allNotes.push({
				id: tg.id,
				name: [
					// { name: tg.start_name, date: tg.datetime },
					...hist.map((i) => ({ name: i[1], date: i[0] })),
				],
				photo: [
					// { photo: tg.now_photo, date: tg.datetime },
					...hist.map((i) => ({ photo: i[3], date: i[0] })),
				],
				about: [
					// { about: tg.start_name, date: tg.datetime },
					...hist.map((i) => ({ about: i[4], date: i[0] })),
				],
				sliderNav: hist.length,
				modifiedDate: tg.datetime,
				createDate: hist[0][0] || tg.datetime,
			});
		}
	});
	return allNotes;
};
const sortByDate = (a, b, columnId) => {
	const prev = Number(a.values[columnId]).toFixed(0);
	const next = Number(b.values[columnId]).toFixed(0);

	if (prev > next) {
		return 1;
	} else if (prev < next) {
		return -1;
	} else {
		return 0;
	}
};

const copyToClipboard = (str) => {
	const el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};
export { formatDate, textFilterFn, formatDataForTable, sortByDate, copyToClipboard };
