import React from 'react';

const Header = () => {
	return (
		<header className='p-3 bg-dark text-white'>
			<div className='container'>
				<div className='d-flex flex-wrap align-items-center justify-content-between'>
					<a
						href='/'
						className='d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none'
					>
						TG
					</a>

					<div className='text-end'>
						<button type='button' className='btn btn-warning'>
							Выйти
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
